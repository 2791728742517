$icon-size: 50px;

.app-header {
	position: fixed;
	width: 100vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2px;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	h1 {
		font-size: $icon-size;
		font-weight: bold;
		font-family: "Calisto MT", serif;
		user-select: none;
	}

	.left-btn-container,
	.right-btn-container {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.left-btn-container {
		justify-content: flex-start;
	}

	.right-btn-container {
		justify-content: flex-end;
	}

	.add-button,
	.close-button,
	.logout-button,
	.edit-button {
		background: none;
		border: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		width: $icon-size;
		height: $icon-size;
		margin-left: 15px;
		margin-right: 15px;
	}

	.close-button {
		font-size: $icon-size - 10;
		color: #e45a3d;
	}

	.add-button,
	.logout-button {
		font-size: $icon-size - 10;
		color: #666666;
	}

	.edit-button img {
		width: $icon-size;
		height: $icon-size;
		border-radius: 50%;
		object-fit: cover;
	}
}

@media (max-width: 520px) {
	.app-container {
		.app-header {
			flex-direction: column;
			text-align: center;

			h1 {
				font-size: 30px;
			}

			.left-btn-container,
			.right-btn-container {
				margin: 0.5rem 0;
				justify-content: center;
			}
		}
	}
}
