.category-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.category-title {
	font-size: 20px;
	margin-left: 15px;
	font-family: "Britannic Bold", serif;
	user-select: none;
}

.category-icon {
	cursor: pointer;
	font-size: 18px;
	margin-right: 10px;
}

.link-container {
	border: 1px solid #ccc;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	margin-bottom: 5px;
}

.link {
	text-decoration: none;
	color: black;
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: 5px;
	user-select: none;
}

.link-image {
	width: 40px;
	height: 40px;
	background-color: #e0e0e0;
	border-radius: 20px;
	margin-right: 10px;
}

.link-text {
	font-size: 16px;
}

.link-icon {
	cursor: pointer;
	font-size: 18px;
	margin-right: 10px;
	margin-bottom: 5px;
	align-self: center;
	margin-left: auto;
}

.empty-category {
	width: 19%;
	visibility: hidden;
}

.categories-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	width: 100%;
	box-sizing: border-box;
	overflow-y: auto;
	padding: 100px 30px 30px;
}

.category-container {
	flex: 0 0 calc(20% - 40px);
	min-width: 200px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
	padding: 20px;
	margin: 0 0.25% 0.25%;

	transition: transform 0.2s ease, box-shadow 0.2s ease;

	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
	}
}

@media (max-width: 1200px) {
	.category-container {
		flex: 0 0 calc(25% - 22.5px);
	}
}

@media (max-width: 900px) {
	.category-container {
		flex: 0 0 calc(33.333% - 20px);
	}
}

@media (max-width: 600px) {
	.categories-grid {
		gap: 20px;
		padding: 20px;
	}

	.category-container {
		flex: 0 0 calc(50% - 10px);
	}
}

@media (max-width: 400px) {
	.category-container {
		flex: 0 0 100%;
	}
}
