.login-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url('../../assets/marseille.png');
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.header-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 2px;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
	z-index: 10;

	h1 {
		color: white;
		text-align: center;
		margin: 0;
		font-size: 50px;
		font-weight: bold;
		font-family: "Calisto MT", serif;
		user-select: none;
	}
}

.login-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 5;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 40px 60px;
	background: rgba(0, 0, 0, 0.7);
	border-radius: 10px;
	min-width: 400px;
	backdrop-filter: blur(5px);
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

	h1 {
		color: white;
		text-align: center;
		margin-bottom: 30px;
		font-size: 2.5rem;
		text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	}

	.login-form-container {
		width: 100%;
	}
}

.footer-overlay {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
	z-index: 10;

	a {
		color: white;
	}
}

.login-button, .abort-button {
	padding: 12px 24px;
	font-size: 1.1rem;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.2s ease;
}

.login-button {
	background-color: #007bff;
	color: white;

	&:hover {
		background-color: #0056b3;
		transform: translateY(-2px);
	}
}

.abort-button {
	background-color: #6c757d;
	color: white;
	margin-top: 15px;
	width: 100%;

	&:hover {
		background-color: #5a6268;
	}
}

@media (max-width: 480px) {
	.login-content {
		min-width: 280px;
		width: 90%;
		padding: 30px 20px;
	}
}

.login-form-container {
	width: 100%;

	.login-form {
		display: flex;
		flex-direction: column;
		gap: 15px;
		width: 100%;

		.form-group {
			input {
				width: 100%;
				padding: 12px;
				border: 1px solid rgba(255, 255, 255, 0.2);
				border-radius: 5px;
				background: rgba(255, 255, 255, 0.1);
				color: white;
				font-size: 1rem;
				transition: all 0.3s ease;

				&::placeholder {
					color: rgba(255, 255, 255, 0.7);
				}

				&:focus {
					outline: none;
					border-color: #007bff;
					background: rgba(255, 255, 255, 0.15);
				}
			}
		}

		.error-message {
			color: #ff4444;
			text-align: center;
			font-size: 0.9rem;
			margin: 5px;
		}

		.form-actions {
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-top: 10px;
		}
	}
}
