.edit-category-form {
	padding: 20px;

	h2 {
		margin-bottom: 20px;
		color: #333;
		user-select: none;
	}

	.form-group {
		margin-bottom: 15px;
		position: relative;
		width: 100%;

		label {
			display: block;
			margin-bottom: 5px;
			color: #666;
		}

		input {
			width: 100%;
			padding: 8px;
			border: 1px solid #ddd;
			border-radius: 4px;

			&:focus {
				outline: none;
				border-color: #007bff;
			}
		}

		input[type="number"] {
			width: 100px;
		}

		.category-suggestions {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background: white;
			border: 1px solid #ccc;
			border-top: none;
			border-radius: 0 0 4px 4px;
			max-height: 200px;
			overflow-y: auto;
			z-index: 1000;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

			li {
				padding: 8px 12px;
				cursor: pointer;
				transition: background-color 0.2s;

				&:hover {
					background-color: #f5f5f5;
				}
			}
		}
	}

	.form-actions {
		display: flex;
		gap: 10px;
		margin-top: 20px;

		button {
			padding: 8px 16px;
			border: none;
			border-radius: 4px;
			cursor: pointer;

			&.btn-save {
				background-color: #007bff;
				color: white;

				&:hover {
					background-color: #0056b3;
				}
			}

			&.btn-remove {
				background-color: #dc3545;
				color: white;

				&:hover {
					background-color: #c82333;
				}
			}

			&.btn-cancel {
				background-color: #6c757d;
				color: white;

				&:hover {
					background-color: #5a6268;
				}
			}
		}
	}

	.category-suggestions {
		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 4px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
}
