.footer-container {
	text-align: center;

	a {
		color: #666;
		font-size: 25px;
		font-family: Chiller, fantasy;
		font-weight: bold;
		margin-left: 7px;
		text-decoration: none;
		user-select: none;
	}
}


