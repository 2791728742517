$sea-blue: #e6f3ff;

.app-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: $sea-blue;
	color: #333;
	font-family: 'Arial', sans-serif;
	user-select: none;

	.app-body {
		flex-grow: 1;
		padding: 2rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.app-footer {
		background-color: #f8f8f8;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
	}
}
