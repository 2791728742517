.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	max-width: 90%;
	width: 500px;
	max-height: 90vh;
	overflow-y: auto;
	position: relative;
	animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
	from {
		opacity: 0;
		transform: translateY(-20px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@media (max-width: 600px) {
	.modal-content {
		width: 95%;
		margin: 10px;
	}
}
