* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    user-select: none;
}

#root {
    height: 100%;
}
